import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { checkAuthentication } from "./scripts/AuthService";
import { NavDstEnum } from "./Constants";
import LoginPage from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import "./App.css"; 
import Login from "./pages/Login";

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Track loading state

  useEffect(() => {
    const fetchAuthStatus = async () => {
      const result = await checkAuthentication();
      setIsAuthenticated(result.isAuthenticated);
      setIsLoading(false);
    };

    fetchAuthStatus();
  }, []);

  // Show a loading indicator until the authentication check is complete
  if (isLoading) {
    return <div className="loader-anim-container">
        <div className="loader-anim"></div>
      </div>;
  }

  return (
    <Router>
      <Routes>
        {/* Redirect root to /login */}
        <Route path="/" element={<Navigate to={isAuthenticated ? NavDstEnum.DASHBOARD : NavDstEnum.LOGIN} />} />
        <Route path={NavDstEnum.LOGIN} element={<Login />} />
        <Route
          path={NavDstEnum.DASHBOARD}
          element={isAuthenticated ? <Dashboard /> : <Navigate to={NavDstEnum.LOGIN} />}
        />
        {/* Catch-all route for undefined paths */}
        <Route path="*" element={<Navigate to={isAuthenticated ? NavDstEnum.DASHBOARD : NavDstEnum.LOGIN} />} />
      </Routes>
    </Router>
  );
};

export default App;
