export const checkAuthentication = async () => {
    try {
      const response = await fetch("../php/checkLogin.php", {
        method: "GET",
        credentials: "include", // Include cookies for session management
      });
  
      const data = await response.json();
  
      if (data.loggedIn) {
        return { isAuthenticated: true, userId: data.user_id || null };
      } else {
        return { isAuthenticated: false };
      }
    } catch (error) {
      alert("Error checking authentication:", error);
      return { isAuthenticated: false };
    }
  };
  