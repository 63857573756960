import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { CurrentButtonStateEnum, CurrentEnabledButtonEnum, NavDstEnum } from "../Constants";
import { checkAuthentication } from "../scripts/AuthService";
import GetUser from "../scripts/GetUser";
import "./Dashboard.css";

import "../tabs/Profile"
import Profile from "../tabs/Profile";

const Dashboard = () => {

  const [enabledButton, setEnabledButtonState] = useState(CurrentEnabledButtonEnum.PROFILE);

  const setEnabledButton = (button) => {
    setEnabledButtonState(button); // Update the enabled button state
    setOpenNavBar(false); // Close the navbar
  };

  const [openNavBar, setOpenNavBar] = useState(false);

  const toggleNavBar = () => {
    setOpenNavBar((prevState) => !prevState);
  };

  const profileRef = useRef(null);
  const [userData, setUserData] = useState({
    user_id: "",
    first_name: "",
    last_name: "",
    header_quote: "",
    github_link: "",
    linkedin_link: "",
    instagram_link: "",
    mail_link: "",
    personal_quote: "",
    resume_link: "",
  });

  const handleSaveClick = async () => {
    if (enabledButton === CurrentEnabledButtonEnum.PROFILE) {
      if (profileRef.current) {
        const isValid = profileRef.current.checkRequiredInputs();

        if (isValid) {
          try {
            // Save user data
            await profileRef.current.saveUserData();
          } catch (error) {
            alert("An error occurred while saving your profile. Please try again.");
          }
        }
      }
    }
  };

  const handleLogOutClick = async () => {
    try {
      const response = await fetch("../php/logout.php", {
        method: "GET",
        credentials: "include",
      });

      if (response.ok) {
        const data = await response.json();
        if (data.success) {
          navigate(NavDstEnum.LOGIN);
        } else {
          alert("Logout failed:", data.error);
        }
      } else {
        alert("Failed to logout, server error");
      }
    } catch (error) {
      alert("Error during logout:", error);
    }
  };

  const navigate = useNavigate();

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Track loading state
  const hasNavigatedRef = useRef(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const authResult = await checkAuthentication();
        setIsAuthenticated(authResult.isAuthenticated);

        if (authResult.isAuthenticated) {
          const fetchedUserData = await GetUser();
          setUserData(fetchedUserData);
        }
      } catch (error) {
        alert("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (!isAuthenticated && !hasNavigatedRef.current && !isLoading) {
      hasNavigatedRef.current = true; // Ensure navigation happens only once
      navigate(NavDstEnum.LOGIN);
    }
  }, [isAuthenticated, navigate]);

  // Show a loading indicator until the authentication check is complete
  if (isLoading) {
    return (
      <div className="loader-anim-container">
        <div className="loader-anim"></div>
      </div>
    );
  }

  return (
    <>
      <div className="main-dashboard-container">
        <div className="left-container-desktop">
          <div className="logo-container">

            <svg className="orange-svg" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="7" cy="7" r="7" fill="#FF8B5A" />
            </svg>

            <p>JL</p>
          </div>

          <button className="side-bar-btn" onClick={handleLogOutClick}>
            LogOut
          </button>

        </div>
        <div className={openNavBar ? "mobile-navbar-open" : "mobile-navbar-close"}>
          <div className="left-container-mobile">
            <div className="logo-container">

              <svg className="orange-svg" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="7" cy="7" r="7" fill="#FF8B5A" />
              </svg>

              <p>JL</p>
            </div>

            <div className="nav-bar-mobile">
              <button
                id={CurrentEnabledButtonEnum.PROFILE}
                className="side-bar-btn"
                onClick={() => setEnabledButton(CurrentEnabledButtonEnum.PROFILE)}
              >
                Profile
              </button>
              <button
                id={CurrentEnabledButtonEnum.EXPERIENCES}
                className="side-bar-btn"
                onClick={() => setEnabledButton(CurrentEnabledButtonEnum.EXPERIENCES)}
              >
                Experiences
              </button>
              <button
                id={CurrentEnabledButtonEnum.PROJECTS}
                className="side-bar-btn"
                onClick={() => setEnabledButton(CurrentEnabledButtonEnum.PROJECTS)}
              >
                Projects
              </button>
              <button
                id={CurrentEnabledButtonEnum.SETTINGS}
                className="side-bar-btn"
                onClick={() => setEnabledButton(CurrentEnabledButtonEnum.SETTINGS)}
              >
                Settings
              </button>
            </div>

            <button className="side-bar-btn" onClick={handleLogOutClick}>
              LogOut
            </button>
          </div>
          <div className="closeNavbarFrame" onClick={toggleNavBar}/>
        </div>
        <div className="right-container">
          <div className="save-btn-container">
            <button className="burger-btn" onClick={toggleNavBar}>
              <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.33325 10H31.6666M8.33325 20H31.6666M8.33325 30H31.6666" stroke="#0F172A" stroke-width="3.33333" stroke-linecap="round" />
              </svg>
            </button>

            <button className="save-btn" onClick={handleSaveClick}>
              Save
            </button>
          </div>
          <div className="content-frame">
            <div className="nav-bar-desktop">
              <button
                id={CurrentEnabledButtonEnum.PROFILE}
                className={enabledButton === CurrentEnabledButtonEnum.PROFILE ? CurrentButtonStateEnum.ENABLED : CurrentButtonStateEnum.DISABLED}
                onClick={() => setEnabledButton(CurrentEnabledButtonEnum.PROFILE)}
              >
                Profile
              </button>
              <button
                id={CurrentEnabledButtonEnum.EXPERIENCES}
                className={enabledButton === CurrentEnabledButtonEnum.EXPERIENCES ? CurrentButtonStateEnum.ENABLED : CurrentButtonStateEnum.DISABLED}
                onClick={() => setEnabledButton(CurrentEnabledButtonEnum.EXPERIENCES)}
              >
                Experiences
              </button>
              <button
                id={CurrentEnabledButtonEnum.PROJECTS}
                className={enabledButton === CurrentEnabledButtonEnum.PROJECTS ? CurrentButtonStateEnum.ENABLED : CurrentButtonStateEnum.DISABLED}
                onClick={() => setEnabledButton(CurrentEnabledButtonEnum.PROJECTS)}
              >
                Projects
              </button>
              <button
                id={CurrentEnabledButtonEnum.SETTINGS}
                className={enabledButton === CurrentEnabledButtonEnum.SETTINGS ? CurrentButtonStateEnum.ENABLED : CurrentButtonStateEnum.DISABLED}
                onClick={() => setEnabledButton(CurrentEnabledButtonEnum.SETTINGS)}
              >
                Settings
              </button>
            </div>

            <div
              className={"content-" + (enabledButton === CurrentEnabledButtonEnum.PROFILE ? CurrentButtonStateEnum.ENABLED : CurrentButtonStateEnum.DISABLED)}
              id={CurrentEnabledButtonEnum.PROFILE}>
              <Profile ref={profileRef} data={userData} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;