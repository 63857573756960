import React, { forwardRef, useImperativeHandle, useEffect, useState } from "react";
import "./Profile.css";

const Profile = forwardRef(({ data }, ref) => {
    // Internal function to validate inputs
    const checkRequiredInputs = () => {
        const requiredInputs = document.querySelectorAll(".required-input");
        let isValid = true;
        requiredInputs.forEach((input) => {
            if (!input.value.trim()) {
                isValid = false;
                input.classList.add("error");
            } else {
                input.classList.remove("error");
            }
        });
        return isValid;
    };

    const [displayedTextData, setDisplayedTextData] = useState({
        user_id: "",
        first_name: "",
        last_name: "",
        header_quote: "",
        github_link: "",
        linkedin_link: "",
        instagram_link: "",
        mail_link: "",
        personal_quote: "",
        resume_link: "",
    });

    useEffect(() => {
        if (data) {
            setDisplayedTextData(data);
        }
    }, [data]);

    // Generic handler to edit tempData
    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setDisplayedTextData((prev) => ({
            ...prev,
            [id]: value,
        }));
    };

    const saveUserData = async () => {
        try {
            const response = await fetch("../php/postUser.php", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(displayedTextData),
            });

            const data = await response.json();

            if (data.success) {
                alert("Successfully updated Profile section.")
            } else {
                alert(`Failed to update Profile section: ${data.error}`);
            }
        } catch (error) {
            alert("Error updating Profile section:", error);
        }
    };

    // Expose the function to the parent
    useImperativeHandle(ref, () => ({
        checkRequiredInputs, saveUserData
    }));

    return (
        <>
            <div className="form-container">
                <div className="form-group">
                    <label htmlFor="user_id" className="form-label">ID</label>
                    <input
                        type="text"
                        id="user_id"
                        className="form-input"
                        placeholder="ID"
                        value={data.user_id}
                        disabled
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="first_name" className="form-label">First Name *</label>
                    <input
                        type="text"
                        id="first_name"
                        className="form-input required-input"
                        placeholder="First Name"
                        value={displayedTextData.first_name}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="last_name" className="form-label">Last Name *</label>
                    <input
                        type="text"
                        id="last_name"
                        className="form-input required-input"
                        placeholder="Last Name"
                        value={displayedTextData.last_name}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="header_quote" className="form-label">Header Quote *</label>
                    <textarea
                        type="text"
                        id="header_quote"
                        className="form-input required-input"
                        placeholder="Header Quote"
                        value={displayedTextData.header_quote}
                        onChange={handleInputChange}

                        style={{
                            height: "100px", 
                            resize: "none", 
                            overflowY: "auto", 
                            whiteSpace: "pre-wrap", 
                        }}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="personal_quote" className="form-label">Personal Quote *</label>
                    <textarea
                        type="text"
                        id="personal_quote"
                        className="form-input required-input"
                        placeholder="Personal Quote"
                        value={displayedTextData.personal_quote}
                        onChange={handleInputChange}

                        style={{
                            height: "100px", 
                            resize: "none", 
                            overflowY: "auto", 
                            whiteSpace: "pre-wrap", 
                        }}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="github_link" className="form-label">GitHub *</label>
                    <input
                        type="text"
                        id="github_link"
                        className="form-input required-input"
                        placeholder="GitHub Link"
                        value={displayedTextData.github_link}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="linkedin_link" className="form-label">LinkedIn *</label>
                    <input
                        type="text"
                        id="linkedin_link"
                        className="form-input required-input"
                        placeholder="LinkedIn Link"
                        value={displayedTextData.linkedin_link}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="instagram_link" className="form-label">Instagram *</label>
                    <input
                        type="text"
                        id="instagram_link"
                        className="form-input required-input"
                        placeholder="Instagram Link"
                        value={displayedTextData.instagram_link}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="mail_link" className="form-label">E-mail *</label>
                    <input
                        type="email"
                        id="mail_link"
                        className="form-input required-input"
                        placeholder="Mail Link"
                        value={displayedTextData.mail_link}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="resume_link" className="form-label">Resume</label>
                    <input
                        type="text"
                        id="resume_link"
                        className="form-input"
                        placeholder="Resume Link"
                        value={displayedTextData.resume_link}
                        disabled
                    />
                </div>
            </div>
        </>
    );
});

export default Profile;