const GetUser = async () => {
    const defaultUserData = {
        user_id: "",
        first_name: "",
        last_name: "",
        header_quote: "",
        github_link: "",
        linkedin_link: "",
        instagram_link: "",
        mail_link: "",
        personal_quote: "",
        resume_link: "",
    };

    try {
        const response = await fetch("../php/getUser.php", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include", 
        });

        const data = await response.json();
        if (data.success) {
            const userKey = Object.keys(data.users)[0];
            const user = data.users[userKey]; 
        
            if (user) {
                return {
                    user_id: user.user_id || "",
                    first_name: user.first_name || "",
                    last_name: user.last_name || "",
                    header_quote: user.header_quote || "",
                    github_link: user.github_link || "",
                    linkedin_link: user.linkedin_link || "",
                    instagram_link: user.instagram_link || "",
                    mail_link: user.mail_link || "",
                    personal_quote: user.personal_quote || "",
                    resume_link: user.resume_link || "",
                };
            }
        } else {
            console.error("Failed to fetch user data:", data.error);
            return defaultUserData; 
        }
    } catch (error) {
        console.error("Error fetching user data:", error);
        return defaultUserData; 
    }
};

export default GetUser;
