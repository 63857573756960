const CurrentEnabledButtonEnum = {
  PROFILE: "profile",
  EXPERIENCES: "experiences",
  PROJECTS: "projects",
  SETTINGS: "settings"
};

const CurrentButtonStateEnum = {
  ENABLED: "enabled",
  DISABLED: "disabled"
};

const NavDstEnum = {
  LOGIN: "/Login",
  DASHBOARD: "/Dashboard",
};

export {
  CurrentButtonStateEnum,
  CurrentEnabledButtonEnum, 
  NavDstEnum
}
  